import { Divider, IconButton, Link } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AppInput from "../../Components/AppInput/AppInput";
import { Button } from "reactstrap";
import ReactToPrint from "react-to-print";
import AppLoader from "../../Components/AppLoader/AppLoader";

const Details = ({ controller }) => {
  const {
    title,
    type,
    name,
    openAddressSearch,
    formatName,
    details,
    getDetails,
    loading,
  } = controller;

  const convertHeight = (height) => {
    // Convert the string to a number
    let heightNum = parseInt(height, 10);

    // Calculate feet and inches
    let feet = Math.floor(heightNum / 100);
    let inches = heightNum % 100;

    // Ensure inches are two digits
    let inchesStr = inches < 10 ? "0" + inches : inches.toString();

    // Return the formatted string
    return `${feet}'${inchesStr}''`;
  };

  const componentRef = useRef(null);

  useEffect(() => {
    getDetails();
  }, []);

  const DriverDetails = () => {
    return (
      <div className="container-fluid p-4 ">
        <div className="row gap-4">
          <div className="col gap-4 row">
            <div className="container-fluid">
              <div className="gap-2 row row-cols-auto gap-4">
                <div className="container">
                  <div>
                    <b>Name</b>
                  </div>
                  <div>{name || "N/A"}</div>
                </div>
                <div className="container">
                  <div>
                    <b>D.O.B</b>
                  </div>
                  <div>{details.BirthDate || "N/A"}</div>
                </div>
                <div className="container">
                  <div>
                    <b>Gender</b>
                  </div>
                  <div>{details.Sex || "N/A"}</div>
                </div>
                <div className="container">
                  <div>
                    <b>Height</b>
                  </div>
                  <div>{details.Height ? convertHeight("501") : "N/A"}</div>
                </div>
                <div className="container">
                  <div>
                    <b>Lic #</b>
                  </div>
                  <div>{details.LicNum || "N/A"}</div>
                </div>
                <div className="container">
                  <div>
                    <b>Expiration Date</b>
                  </div>
                  <div>{details.ExpirationDate || "N/A"}</div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="gap-2 row row-cols-auto gap-4">
                <div className="container">
                  <div>
                    <b>Legal Address:</b>
                  </div>
                  {details.MailAddrSt1 ||
                  details.MailAddrSt2 ||
                  details.MailAddrCity ||
                  details.MailAddrState ||
                  details.MailAddrZip ? (
                    <div>
                      <div>
                        {details.MailAddrSt1} {details.MailAddrSt2}{" "}
                      </div>
                      <div>
                        {details.MailAddrCity} {details.MailAddrState}{" "}
                        {details.MailAddrZip}
                      </div>
                      <div>
                        <div className="col-2">
                          <Link
                            href={encodeURI(
                              `https://maps.google.com/?q=${details.MailAddrSt1}+${details.MailAddrSt2}+${details.MailAddrCity}+${details.MailAddrState}+${details.MailAddrZip}`
                            )}
                            target="_blank"
                          >
                            Map
                          </Link>
                          <Link
                            className="ps-2"
                            onClick={() => {
                              openAddressSearch({
                                Street1: details.MailAddrSt1,
                                Street2: details.MailAddrSt2,
                                City: details.MailAddrCity,
                                State: details.MailAddrState,
                                Zip: details.MailAddrZip,
                              });
                            }}
                          >
                            Search
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>N/A</div>
                  )}
                </div>
                <div className="container">
                  <div>
                    <b>Residential Address:</b>
                  </div>
                  {details.ResAddrSt1 ||
                  details.ResAddrSt2 ||
                  details.ResAddrCity ||
                  details.ResAddrState ||
                  details.ResAddrZip ? (
                    <div>
                      <div>
                        {details.ResAddrSt1} {details.ResAddrSt2}{" "}
                      </div>
                      <div>
                        {details.ResAddrCity} {details.ResAddrState}{" "}
                        {details.ResAddrZip}
                      </div>
                      <div>
                        <div className="col-2">
                          <Link
                            href={encodeURI(
                              `https://maps.google.com/?q=${details.ResAddrSt1}+${details.ResAddrSt2}+${details.ResAddrCity}+${details.ResAddrState}+${details.ResAddrZip}`
                            )}
                            target="_blank"
                          >
                            Map
                          </Link>
                          <Link
                            className="ps-2"
                            onClick={() => {
                              openAddressSearch({
                                Street1: details.ResAddrSt1,
                                Street2: details.ResAddrSt2,
                                City: details.ResAddrCity,
                                State: details.ResAddrState,
                                Zip: details.ResAddrZip,
                              });
                            }}
                          >
                            Search
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>N/A</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const VehicleDetails = () => {
    return (
      <div className="container-fluid p-4 ">
        <div className="row gap-4">
          <div className="col-lg-5">
            <div className="container-fluid">
              <div className="gap-4 row">
                <div className="container">
                  <div>
                    <b>VIN</b>
                  </div>
                  <div>{details.VIN || "N/A"}</div>
                </div>
                <div className="container">
                  <div className="container-fluid">
                    <div className="row row-cols-auto gap-4">
                      <div className="container">
                        <div>
                          <b>Model year</b>
                        </div>
                        <div>{details.ModelYear || "N/A"}</div>
                      </div>
                      <div className="container">
                        <div>
                          <b>Make</b>
                        </div>
                        <div>{details.Manufacturer || "N/A"}</div>
                      </div>
                      <div className="container">
                        <div>
                          <b>Model</b>
                        </div>
                        <div>{details.ModelName || "N/A"}</div>
                      </div>
                      <div className="container">
                        <div>
                          <b># Cyl</b>
                        </div>
                        <div>{details.NumCylinder || "N/A"}</div>
                      </div>
                      <div className="container">
                        <div>
                          <b># Passengers</b>
                        </div>
                        <div>{details.NumPassengers || "N/A"}</div>
                      </div>
                      <div className="container">
                        <div>
                          <b># Doors</b>
                        </div>
                        <div>{details.NumDoors || "N/A"}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="container-fluid">
                    <div className="row row-cols-auto gap-4">
                      <div className="container">
                        <div>
                          <b>Color (Primary)</b>
                        </div>
                        <div>{details.PriColor || "N/A"}</div>
                      </div>
                      <div className="container">
                        <div>
                          <b>Color (Secondary)</b>
                        </div>
                        <div>{details.SecColor || "N/A"}</div>
                      </div>
                      <div className="container">
                        <div>
                          <b>Weight</b>
                        </div>
                        <div>{details.Weight || "N/A"}</div>
                      </div>
                      <div className="container">
                        <div>
                          <b>Type</b>
                        </div>
                        <div>{details.VehTypeName || "N/A"}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="container-fluid">
                    <div className="row">
                      <b>Registration - </b>
                    </div>
                    <div className="row row-cols-auto gap-4">
                      <div className="container">
                        <div>
                          <b>Issue Date</b>
                        </div>
                        <div>{details.RegIssueDate || "N/A"}</div>
                      </div>
                      <div className="container">
                        <div>
                          <b>Effective Date</b>
                        </div>
                        <div>{details.RegEffectiveDate || "N/A"}</div>
                      </div>
                      <div className="container">
                        <div>
                          <b>Expire Date</b>
                        </div>
                        <div>{details.RegExpDate || "N/A"}</div>
                      </div>
                      <div className="container">
                        <div>
                          <b>Prefix</b>
                        </div>
                        <div>{details.RegPrefix || "N/A"}</div>
                      </div>
                      <div className="container">
                        <div>
                          <b>Number</b>
                        </div>
                        <div>{details.RegNumber || "N/A"}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="container-fluid">
                    <div className="row row-cols-auto gap-4">
                      <div className="container">
                        <div>
                          <b>Garaged Code</b>
                        </div>
                        <div>{details.GaragedName || "N/A"}</div>
                      </div>
                      <div className="container">
                        <div>
                          <b>Insurance Code</b>
                        </div>
                        <div>{details.InsuranceCode || "N/A"}</div>
                      </div>
                      <div className="container">
                        <div>
                          <b>Title Num</b>
                        </div>
                        <div>{details.TitleNum || "N/A"}</div>
                      </div>
                      <div className="container">
                        <div>
                          <b>Co Name</b>
                        </div>
                        <div>{details.CorpName || "N/A"}</div>
                      </div>
                      <div className="container">
                        <div>
                          <b>Plate Color</b>
                        </div>
                        <div>{details.RegColor || "N/A"}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="container-fluid">
              <div className="gap-2 row">
                <div className="container">
                  <div>
                    <b>Primary Owner</b>
                  </div>
                  <div className="container-fluid">
                    <div className="row row-cols-auto gap-4">
                      <div className="col">
                        <div>
                          <b>Name</b>
                        </div>
                        <div>
                          {formatName(
                            details.Owner1FirstName,
                            details.Owner1MiddleName,
                            details.Owner1LastName,
                            details.Owner1Suffix
                          ) || "N/A"}
                        </div>
                      </div>
                      <div className="col">
                        <div>
                          <b>DOB</b>
                        </div>
                        <div>{details.Owner1DOB || "N/A"}</div>
                      </div>
                      <div className="col">
                        <div>
                          <b>Lic #</b>
                        </div>
                        <div>{details.Owner1LicNum || "N/A"}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div>
                    <b>Secondary Owner</b>
                  </div>
                  <div className="container-fluid">
                    <div className="row row-cols-auto gap-4">
                      <div className="col">
                        <div>
                          <b>Name</b>
                        </div>
                        <div>
                          {formatName(
                            details.Owner2FirstName,
                            details.Owner2MiddleName,
                            details.Owner2LastName,
                            details.Owner2Suffix
                          ) || "N/A"}
                        </div>
                      </div>
                      <div className="col">
                        <div>
                          <b>DOB</b>
                        </div>
                        <div>{details.Owner2DOB || "N/A"}</div>
                      </div>
                      <div className="col">
                        <div>
                          <b>Lic #</b>
                        </div>
                        <div>{details.Owner2LicNum || "N/A"}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container">
                  <div>
                    <b>Mail Address:</b>
                  </div>
                  {details.Owner1MailStreet1 ||
                  details.Owner1MailStreet2 ||
                  details.Owner1MailCity ||
                  details.Owner1MailState ||
                  details.Owner1MailZip ? (
                    <div>
                      <div>
                        {details.Owner1MailStreet1} {details.Owner1MailStreet2}{" "}
                      </div>
                      <div>
                        {details.Owner1MailCity} {details.Owner1MailState}{" "}
                        {details.Owner1MailZip}
                      </div>
                      <div>
                        <div className="col-2">
                          <Link
                            href={encodeURI(
                              `https://maps.google.com/?q=${details.Owner1MailStreet1}+${details.Owner1MailStreet2}+${details.Owner1MailCity}+${details.Owner1MailState}+${details.Owner1MailZip}`
                            )}
                            target="_blank"
                          >
                            Map
                          </Link>
                          <Link
                            className="ps-2"
                            onClick={() => {
                              openAddressSearch({
                                Street1: details.Owner1MailStreet1,
                                Street2: details.Owner1MailStreet2,
                                City: details.Owner1MailCity,
                                State: details.Owner1MailState,
                                Zip: details.Owner1MailZip,
                              });
                            }}
                          >
                            Search
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>N/A</div>
                  )}
                </div>
                <div className="container">
                  <div>
                    <b>Residence Address:</b>
                  </div>
                  {details.Owner1ResStreet1 ||
                  details.Owner1ResStreet2 ||
                  details.Owner1ResCity ||
                  details.Owner1ResState ||
                  details.Owner1ResZip ? (
                    <div>
                      <div>
                        {details.Owner1ResStreet1} {details.Owner1ResStreet2}{" "}
                      </div>
                      <div>
                        {details.Owner1ResCity} {details.Owner1ResState}{" "}
                        {details.Owner1ResZip}
                      </div>
                      <div>
                        <div className="col-2">
                          <Link
                            href={encodeURI(
                              `https://maps.google.com/?q=${details.Owner1ResStreet1}+${details.Owner1ResStreet2}+${details.Owner1ResCity}+${details.Owner1ResState}+${details.Owner1ResZip}`
                            )}
                            target="_blank"
                          >
                            Map
                          </Link>
                          <Link
                            className="ps-2"
                            onClick={() => {
                              openAddressSearch({
                                Street1: details.Owner1ResStreet1,
                                Street2: details.Owner1ResStreet2,
                                City: details.Owner1ResCity,
                                State: details.Owner1ResState,
                                Zip: details.Owner1ResZip,
                              });
                            }}
                          >
                            Search
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>N/A</div>
                  )}
                </div>
                <div className="container">
                  <div>
                    <b>Lien Holder: </b>
                  </div>
                  <div>{details.LienHolder1Name || "N/A"}</div>
                </div>
                <div className="container">
                  <div>
                    <b>Lien Holder Address:</b>
                  </div>
                  {details.LienHolder1Address ||
                  details.LienHolder1City ||
                  details.LienHolder1State ||
                  details.LienHolder1Zip ? (
                    <div>
                      <div>{details.LienHolder1Address}</div>
                      <div>
                        {details.LienHolder1City} {details.LienHolder1State}{" "}
                        {details.LienHolder1Zip}
                      </div>
                      <div>
                        <div className="col-2">
                          <Link
                            href={encodeURI(
                              `https://maps.google.com/?q=${details.LienHolder1Address}+${details.LienHolder1City}+${details.LienHolder1State}+${details.LienHolder1Zip}`
                            )}
                            target="_blank"
                          >
                            Map
                          </Link>
                          <Link
                            className="ps-2"
                            onClick={() => {
                              openAddressSearch({
                                Street1: details.LienHolder1Address,
                                City: details.LienHolder1City,
                                State: details.LienHolder1State,
                                Zip: details.LienHolder1Zip,
                              });
                            }}
                          >
                            Search
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>N/A</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!details && !loading) {
    return (
      <div className="text-danger text-center pt-4">Failed to load data!</div>
    );
  } else if (loading) {
    return <AppLoader isLoading={loading} />;
  }

  return (
    <div ref={componentRef}>
      <div className="container-fluid px-4">
        <div className="row pageTitle section-title">
          <div className=" d-flex flew-row gap-4">
            <div>{title}</div>
            {!loading && (
              <div>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      className="print-icon"
                      onClick={() => {
                        window.print();
                      }}
                    >
                      Print
                    </Button>
                  )}
                  documentTitle={title}
                  pageStyle={() => {
                    return ".print-icon{visibility:hidden;} a{display:none}";
                  }}
                  content={() => componentRef.current}
                />
              </div>
            )}
          </div>
        </div>
        <Divider />
        {!loading ? (
          type === "DL" ? (
            DriverDetails()
          ) : (
            VehicleDetails()
          )
        ) : (
          <div className="text-center pt-4">Loading Data..</div>
        )}
      </div>
    </div>
  );
};

export default Details;
