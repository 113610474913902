import { toast } from "react-toastify";
import { handleSubscriptionSearch } from "../../Utils/services/subscription-search.service";
import { useAuth } from "../../Utils/AuthContext";

export const tabController = (tab, tabs, datasets) => {
  const tabIndex = tabs.fields.findIndex((e) => e.key === tab.key);

  const addressSearchIndex =
    tabs.fields
      .filter(
        (e) =>
          e.isSearch &&
          e.isAddressSearch &&
          tab.isAddressSearch &&
          e.query?.dataset?.type === tab.query?.dataset?.type
      )
      .findIndex((e) => tab.key === e.key) + 1;

  const normalSearchIndex =
    tabs.fields
      .filter(
        (e) =>
          e.isSearch &&
          !e.isAddressSearch &&
          !tab.isAddressSearch &&
          e.query?.dataset?.type === tab.query?.dataset?.type
      )
      .findIndex((e) => tab.key === e.key) + 1;

  const addressSearchTitle = `${
    tab.query?.dataset?.type === "DL" ? "Driver" : "Vehicle"
  } Address Search ${addressSearchIndex}`;

  const normalSearchTitle =
    tab.query?.dataset?.type === "DL"
      ? `Driver Search ${normalSearchIndex}`
      : `MVR Search ${normalSearchIndex}`;

  const formatName = (firstName, middleName, lastName, suffix) => {
    return (
      firstName +
      " " +
      (middleName ? middleName + " " : "") +
      lastName +
      (suffix ? " " + suffix : "")
    )
      .trimEnd()
      .trimStart();
  };

  const name = formatName(
    tab.details?.FirstName,
    tab.details?.MidName,
    tab.details?.LastName,
    tab.details?.Suffix
  );

  const results = tab.results;

  const query = tab.query;

  const type = query.dataset.type;

  const headers = tab.headers;

  const loading = tab.loading;

  const details = tab.details;

  const title = `${
    tab.isDetails
      ? loading
        ? "Loading..."
        : !tab.details
        ? "No Data"
        : tab.query.dataset.type === "DL"
        ? name
        : tab.details.VIN
      : tab.isAddressSearch
      ? addressSearchTitle
      : normalSearchTitle
  }`;

  const openDetails = ({ id }) => {
    tabs.append({
      isSearch: false,
      isAddressSearch: false,
      isDetails: true,
      recordId: id,
      query: {
        dataset: {
          ...query.dataset,
        },
      },
      key: Math.random().toString(),
    });
  };

  const getDetails = async () => {
    tabs.update(tabIndex, {
      ...tabs.fields[tabIndex],
      loading: true,
    });

    const request = tabs.fields[tabIndex].recordId
      ? await handleSubscriptionSearch(query, {
          dataset: {
            id: query.dataset.id,
          },
          queryParams: {
            Id: tabs.fields[tabIndex].recordId,
          },
          paginationParams: {
            pageIndex: 0,
            resultsPerPage: 10,
            sortBy: type === "DL" ? "LastName" : "VIN",
            sortOrder: "Desc",
          },
        })
      : null;

    tabs.update(tabIndex, {
      ...tabs.fields[tabIndex],
      details:
        request && request.data && request.data.length === 1
          ? request.data[0]
          : null,
      loading: false,
    });
  };

  const openAddressSearch = (details) => {
    tabs.append({
      isSearch: true,
      isAddressSearch: true,
      isDetails: false,
      query: {
        dataset: {
          ...query.dataset,
        },
        query: {
          ...details,
        },
      },
      key: Math.random().toString(),
    });
  };

  const formatDate = (date) => {
    const year = date?.year?.padEnd(4, "*");
    const month = date?.month?.padStart(2, "*");
    const day = date?.day?.padStart(2, "*");

    return `${year || "****"}-${month || "**"}-${day || "**"}`;
  };

  const updateData = async ({ query, results, pagination }) => {
    const previouseQuery = query;

    tabs.update(tabIndex, {
      ...tabs.fields[tabIndex],
      query: previouseQuery,
      loading: true,
    });

    // if (query.query.BirthDate) {
    //   query.query.BirthDate = formatBirthday(query.query.BirthDate);
    // }

    try {
      const request = await handleSubscriptionSearch(query, {
        dataset: {
          id: query.dataset.id,
        },
        queryParams: Object.fromEntries(
          Object.entries({
            ...query.query,
            BirthDate:
              query.query &&
              query.query.BirthDate
                ? formatDate(query.query.BirthDate)
                : null,
            RegEffectiveDate:
              query.query &&
              query.query.RegEffectiveDate
                ? formatDate(query.query.RegEffectiveDate)
                : null,
            RegIssueDate:
              query.query &&
              query.query.RegIssueDate
                ? formatDate(query.query.RegIssueDate)
                : null,
            RegExpDate:
              query.query &&
              query.query.RegExpDate
                ? formatDate(query.query.RegExpDate)
                : null,
          }).filter(([key, value]) => value !== null && value !== "") .map(([key, value]) => [key, typeof value === 'string' ? value.trimEnd().trimStart() : value])
        ),
        paginationParams: {
          pageIndex: (pagination?.currentPage || tab.currentPage || 1) - 1,
          resultsPerPage:
            pagination?.resultsPerPage || tab.resultsPerPage || 10,
          sortBy:
            pagination?.sortBy ||
            tab.sortBy ||
            (query.dataset.type === "DL" ? "LastName" : "VIN"),
          sortOrder: pagination?.sortOrder || tab.sortOrder || "Desc",
        },
      });

      //console.log(  (query.dataset.type ));

      tabs.update(tabIndex, {
        ...tabs.fields[tabIndex],
        results: request?.data || [],
        totalResults: request?.totalCount || 0,
        currentPage: request?.currentPageIndex + 1 || 0,
        resultsPerPage: request?.resultsPerPage || 0,
        sortBy: pagination?.sortBy || tab.sortBy || (query.dataset.type === "DL" ? "LastName" : "VIN"),
        sortOrder: pagination?.sortOrder || tab.sortOrder || "Desc",
        loading: false,
        query: previouseQuery,
      });
    } catch (e) {
      toast.error(e.message, {
        position: "bottom-center",
      });
      tabs.update(tabIndex, {
        ...tabs.fields[tabIndex],
        query: previouseQuery,
        loading: false,
      });
    }
  };

  //   const datasets =
  //     availableDatasets &&
  //     availableDatasets.filter((e) => e.type === query.dataset.type);

  return {
    title,
    tab,
    getDetails,
    results,
    headers,
    updateData,
    query,
    openDetails,
    openAddressSearch,
    details,
    loading,
    type,
    name,
    formatName,
    datasets,
  };
};
