import React, { useEffect, useState } from "react";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Chip,
  Drawer,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import useTable from "../../Components/useTable";
import { columns } from "./columns";
import editIcon from "../../assets/icons/edit-2-outline.svg";
import trashIcon from "../../assets/icons/trash-2-outline.svg";
import infoIcon from "../../assets/icons/info.svg";
import refreshOutline from "../../assets/icons/refresh-outline.svg";
import addPerson from "../../assets/icons/sidebar/person-add-outline.svg";
import AssignUsers from "./AssignUsers";
import {
  deleteSubscriptionDatasetBySuperAdmin,
  getAllSubscriptionDatasetsForSuperAdmin,
  getSubscriptionLicensesForTenantAdmin,
} from "../../Utils/services/subscription-datasets.service";
import { Auth } from "../../Utils/helpers";
import { format } from "date-fns";
import { ToastrService } from "../../Utils/services/tostr.service";
import AppLoader from "../../Components/AppLoader/AppLoader";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const data = [
  {
    id: 0,
    name: "Dataset 1",
    state: "ID",
    type: "MVR",
    year: "2021",
    version: "Q4",
    createdAt: "01/01/2021",
    createdBy: "John Doe",
    available_licenses: 13,
    status: "draft",
  },
  {
    id: 1,
    name: "Dataset 2",
    state: "ID",
    type: "MVR",
    year: "2021",
    version: "Q4",
    createdAt: "01/01/2021",
    createdBy: "John Doe",
    available_licenses: 13,
    status: "processing",
  },
  {
    id: 2,
    name: "Dataset 3",
    state: "ID",
    type: "DL",
    year: "2021",
    version: "Q3",
    createdAt: "01/01/2021",
    createdBy: "John Doe",
    available_licenses: 1,
    status: "success",
  },
];

const SubscriptionDatasets = () => {
  useDocumentTitle("Subscription Datasets");

  const history = useHistory();

  const [assignUsersDrawer, setAssignUsersDrawer] = useState();
  const [datasets, setDatasets] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState();
  const [failureReasonModal, setFailureReasonModal] = useState();
  const [lastViewedDataset, setLastViewedDataset] = useState();

  const isTenantAdmin =
    Auth.getUserRole().includes("COMPANY_ADMIN") ||
    Auth.getUserRole().includes("ADMIN");

  const filteredColumns = columns.filter((e) => {
    if (isTenantAdmin && e.tenantAdmin) {
      return e;
    } else if (!isTenantAdmin && !e.hideInAdmin) {
      return e;
    }
  });

  const { TblContainer, TblHead } = useTable(null, filteredColumns);

  const getDatasets = async () => {
    setLoading(true);
    let data = [];
    if (!isTenantAdmin) {
      const res = await getAllSubscriptionDatasetsForSuperAdmin();
      if (res) {
        data = res;
      } else {
      }
    } else {
      const res = await getSubscriptionLicensesForTenantAdmin();
      if (res && res.subscriptions) {
        res.subscriptions.forEach((dataset) => {
          data.push({
            id: dataset.dataSet.id,
            name: dataset.dataSet.name,
            state: dataset.dataSet.state,
            type: dataset.dataSet.type,
            year: dataset.dataSet.year,
            version: dataset.dataSet.version,
            createdAt: dataset.dataSet.createdAt,
            createdBy: dataset.dataSet.createdBy,
            total_seats: dataset.maxSeatCount,
            assigned_seats: dataset.assignedSeats,
            available_seats: (
              dataset.maxSeatCount - dataset.assignedSeats.length
            ).toString(),
            status: dataset.status,
          });
        });
      } else {
      }
    }
    //console.log(data);
    setDatasets(data);
    setLoading(false);
  };

  const { search } = useLocation();

  const query = new URLSearchParams(search);

  const handleDeleteDataset = async (dataset) => {
    setLoading(true);
    setLoadingMessage("Deleting " + dataset.name);
    const request = await deleteSubscriptionDatasetBySuperAdmin(dataset.id);

    await getDatasets();
    setLoading(false);
    setLoadingMessage();
    ToastrService.success(
      "Dataset " + dataset.name + " was successfully Deleted!"
    );
  };

  useEffect(() => {
    const prev = query.get("prev");
    if (prev) {
      setLastViewedDataset(prev);
      history.replace(window.location.pathname);
    }
    getDatasets();
  }, []);

  const openFailureReasonModal = (id) => {
    setFailureReasonModal(id);
  };

  const closeFailureReasonModal = () => {
    setFailureReasonModal();
  };

  return (
    <div className="container-fluid">
      <AppLoader isLoading={loading} message={loadingMessage} />
      <div className="d-flex justify-content-between">
        <div className="pageTitle">Subscription Datasets</div>

        <div className="d-flex gap-2 align-items-center">
          <Tooltip title="Refresh Datasets">
            <img
              src={refreshOutline}
              onClick={() => {
                getDatasets();
                // setLastViewedDataset();
              }}
              style={{
                cursor: "pointer",
                width: 24,
              }}
            />
          </Tooltip>

          {!isTenantAdmin && (
            <div className="accountListIcons">
              <Tooltip title="Add Subscription Dataset">
                <div
                  className="smallAddIcon"
                  style={{
                    marginTop: 4,
                  }}
                  onClick={() => {
                    history.push("/account/subscription/dataset");
                  }}
                ></div>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      {!loading && datasets && datasets.length === 0 && (
        <div className="text-danger">
          No Dataset available.{" "}
          {isTenantAdmin
            ? "Contact WWI Admin to add datasets to your account!"
            : "Please add subscription datasets"}
        </div>
      )}
      <div className="row mt-4">
        <Drawer
          open={assignUsersDrawer ? true : false}
          onClose={() => setAssignUsersDrawer()}
          anchor="right"
          PaperProps={{
            style: {
              width: "50%",
              padding: 0,
            },
          }}
        >
          <AssignUsers
            selectedDataset={assignUsersDrawer}
            closeDrawer={() => setAssignUsersDrawer()}
            onSave={async () => {
              getDatasets();
            }}
            isTenantAdmin={isTenantAdmin}
          />
        </Drawer>
        <TblContainer>
          <TblHead style={{ position: "sticky", top: 48 }} />
          <TableBody>
            {datasets &&
              datasets.length > 0 &&
              datasets.map((data, i) => {
                return (
                  <TableRow
                    key={data.id + i}
                    className={lastViewedDataset === data.id ? "bg-light" : ""}
                  >
                    {filteredColumns.map((column) => {
                      if (column.id === "assigned_seats") {
                        return (
                          <TableCell key={column.id}>
                            {data.assigned_seats.length}
                          </TableCell>
                        );
                      }

                      if (column.id === "createdAt") {
                        return (
                          <TableCell key={column.id}>
                            {format(data.createdAt, "PPpp")}
                          </TableCell>
                        );
                      }

                      if (column.id === "publishedAt" && data.publishedAt) {
                        return (
                          <TableCell key={column.id}>
                            {data.publishedAt !== -1
                              ? format(data.publishedAt, "PPpp")
                              : "-"}
                          </TableCell>
                        );
                      }

                      if (column.id === "actions") {
                        return (
                          <TableCell align="right" key={column.id}>
                            {!isTenantAdmin &&
                            data["status"].toString().toLowerCase() ===
                              "draft" ? (
                              <Tooltip title="Continue Editing">
                                <img
                                  src={editIcon}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    history.push(
                                      "/account/subscription/dataset?id=" +
                                        data.id
                                    );
                                  }}
                                />
                              </Tooltip>
                            ) : !isTenantAdmin &&
                              data["status"].toString().toLowerCase() ===
                                "failed" ? (
                              <span>
                                <Tooltip title={"Show Failure Reason"}>
                                  <img
                                    src={infoIcon}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      openFailureReasonModal(data.id);
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip title="Delete Failed Dataset">
                                  <img
                                    src={trashIcon}
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: 10,
                                    }}
                                    onClick={() => handleDeleteDataset(data)}
                                  />
                                </Tooltip>
                                <Modal
                                  isOpen={
                                    failureReasonModal &&
                                    failureReasonModal === data.id
                                  }
                                  size="lg"
                                  toggle={() => closeFailureReasonModal()}
                                >
                                  <ModalHeader
                                    toggle={() => closeFailureReasonModal()}
                                  >
                                    Failure Reason ({data.name})
                                  </ModalHeader>
                                  <ModalBody>
                                    <div style={{ wordWrap: "break-word" }}>
                                      {data.failureReason}
                                    </div>
                                  </ModalBody>
                                </Modal>
                              </span>
                            ) : (
                              ""
                            )}
                            {(isTenantAdmin ||
                              (!isTenantAdmin &&
                                data["status"].toString().toLowerCase() ===
                                  "published")) && (
                              <Tooltip
                                title={
                                  isTenantAdmin
                                    ? "Assign Users"
                                    : "Assign Accounts"
                                }
                              >
                                <img
                                  src={addPerson}
                                  style={{
                                    cursor: "pointer",
                                    width: 24,
                                  }}
                                  onClick={() => {
                                    setAssignUsersDrawer(data);
                                  }}
                                />
                              </Tooltip>
                            )}
                          </TableCell>
                        );
                      }

                      if (column.id === "status") {
                        return (
                          <TableCell key={column.id}>
                            <Chip
                              size="small"
                              label={data[column.id]}
                              style={{
                                textTransform: "uppercase",
                              }}
                              color={
                                data[column.id].toString().toLowerCase() ===
                                "published"
                                  ? "success"
                                  : data[column.id].toString().toLowerCase() ===
                                    "failed"
                                  ? "error"
                                  : data[column.id].toString().toLowerCase() ===
                                    "processing"
                                  ? "info"
                                  : "default"
                              }
                            />
                          </TableCell>
                        );
                      }

                      if (column.id === "name") {
                        return (
                          <TableCell key={column.id}>
                            {data[column.id] || "-"}
                            {lastViewedDataset === data.id && (
                              <div
                                className="text-small"
                                style={{ fontSize: 12 }}
                              >
                                Recently Configured
                              </div>
                            )}
                          </TableCell>
                        );
                      }

                      if (column.id === "total_seats") {
                        return (
                          <TableCell key={column.id}>
                            {data[column.id]}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell key={column.id}>
                          {data[column.id] || "-"}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </TblContainer>
      </div>
    </div>
  );
};

export default SubscriptionDatasets;
