import {
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Input, Label } from "reactstrap";
import AppInput from "../../Components/AppInput/AppInput";
import AppSelect from "../../Components/AppSelect/AppSelect";
import "./SubscriptionSearchQueryForm.css";
import DppaModal from "../../Components/DppaModal/DppaModal";
import LeftArrow from "../../assets/icons/left_arrow.svg";
import RightArrow from "../../assets/icons/right_arrow.svg";

import AppLoader from "../../Components/AppLoader/AppLoader";
import { getAccountDatasetsForSubscriptionUser } from "../../Utils/services/subscription-datasets.service";
import { toast } from "react-toastify";
import { useAuth } from "../../Utils/AuthContext";
import { ToastrService } from "../../Utils/services/tostr.service";
const SubscriptionSearchQueryForm = ({
  controller,
  closeDrawer,
  toggleDppa,
}) => {
  const { title, tab, dppaModal, setDppaModal, updateData, query, datasets } =
    controller;

  const { trigger, formState:{ errors,isValid}, clearErrors, watch, register, setValue, getValues, setError } = useForm({
    defaultValues: {
      ...query,
    },
  });

  const [availableDatasets, setAvailableDatasets] = useState();
  const [fetchingDatasets, setFetchingDatasets] = useState(false);


  useEffect(() => {
    const data = datasets;

    if (!data) {
      return;
    }

    const datasetForType =
      data && data.length > 0
        ? data.filter((e) => e.type === query.dataset.type)
        : [];

    let dataset = [];

    datasetForType.forEach((item) => {
      const ifExists = dataset.find((e) => e.state === item.state);

      if (!ifExists) {
        dataset.push({
          state: item.state,
          year: [item.year],
          version: [item.version],
        });
      } else {
        dataset.forEach((d) => {
          if (d.state === item.state) {
            d.year.push(item.year);
            d.version.push(item.version);
          }
        });
      }
    });

    // console.log(state, year, version);
    setAvailableDatasets(datasetForType);
  }, [datasets]);

  const handleSearch = async () => {
    closeDrawer();
    await updateData({
      query: getValues(),
      pagination: {
        currentPage: 1,
      },
    });
  };

  const availableStates = !fetchingDatasets
    ? Array.from(
        new Set(
          availableDatasets && availableDatasets.map((item) => item.state)
        )
      )
    : [];

  const availableYears = Array.from(
    new Set(
      availableDatasets &&
        availableDatasets
          .filter((item) => item.state === watch("dataset.state"))
          .map((item) => item.year)
    )
  );

  const availableVersions = Array.from(
    new Set(
      availableDatasets &&
        availableDatasets
          .filter(
            (item) =>
              item.state === watch("dataset.state") &&
              item.year === watch("dataset.year")
          )
          .map((item) => item.version)
    )
  );

  const formatMonth = (e, key) => {

    let input = e.target.value;

    // Allow only digits and '*' using regex
    let cleanedInput = input.replace(
        /[^0-9*]/g,
        ""
    );

    if(cleanedInput !== "" && parseInt(cleanedInput[0]) > 1) {
      cleanedInput = `0${cleanedInput}`;
    } else if(cleanedInput !== "" && parseInt(cleanedInput[0]) === 0 && parseInt(cleanedInput[1]) === 0){
      cleanedInput = "0";
    } else if(cleanedInput !== "" && parseInt(cleanedInput[0]) === 1 && parseInt(cleanedInput[1]) > 2){
      cleanedInput = `1`;
    }

    if(cleanedInput !== "" && cleanedInput.length < 2){
      setError(`query.${key}.month`, { message: "Must be 2 Characters (* as Wildcard)" });
    } else {
      clearErrors(`query.${key}.month`)
      trigger();
    }

    setValue(
        `query.${key}.month`,
        cleanedInput.length <= 2
            ?  cleanedInput
            :  watch(`query.${key}.month`)
    );
  }

  const formatDay = (e, key) => {
    let input = e.target.value;

    // Allow only digits and '*' using regex
    let cleanedInput = input.replace(
        /[^0-9*]/g,
        ""
    );

    if(cleanedInput !== "" && parseInt(cleanedInput[0]) > 3) {
      cleanedInput = `0${cleanedInput}`;
    }  else if(cleanedInput !== "" && parseInt(cleanedInput[0]) === 0 && parseInt(cleanedInput[1]) === 0){
      cleanedInput = "0";
    }  else if(cleanedInput !== "" && parseInt(cleanedInput[0]) === 3 && parseInt(cleanedInput[1]) > 1){
      cleanedInput = `3`;
    }

    if(cleanedInput !== "" && cleanedInput.length < 2){
      setError(`query.${key}.day`, { message: "Must be 2 Characters (* as Wildcard)" });
    } else {
      clearErrors(`query.${key}.day`)
      trigger();
    }

    setValue(
        `query.${key}.day`,
        cleanedInput.length <= 2
            ?  cleanedInput
            :  watch(`query.${key}.day`)
    );
  }


  const formatYear = (e, key) => {
    let input = e.target.value;

    // Allow only digits and '*' using regex
    let cleanedInput = input.replace(
        /[^0-9*]/g,
        ""
    );

    if(cleanedInput !== "" && cleanedInput.length < 4){
      setError(`query.${key}.year`, { message: "Must be 4 Characters (* as Wildcard)" });
    } else {
      clearErrors(`query.${key}.year`)
      trigger();
    }

    setValue(
        `query.${key}.year`,
        cleanedInput.length <= 4
            ? cleanedInput
            : watch(`query.${key}.year`)
    );
  }



  return (
    <form>
      <div className="container-fluid">
        <div
          className="row pageTitle section-title sticky-top  text-center"
          style={{ background: "white" }}
        >
          <div className="row">
            <div className="col">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 text-center">{title} Query</div>
              </div>
            </div>
            <div className="col-auto pe-2">
              <Tooltip title="Close Search Query">
                <IconButton color="primary" onClick={closeDrawer}>
                  <img src={RightArrow} width={20} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <Divider />
        </div>

        <div className="px-3 pb-4">
          <div className="container ">
            <div className="row pageTitle section-title">
              1. Choose your Dataset
            </div>
            {(!availableDatasets ||
              (availableDatasets && availableDatasets.length === 0)) && (
              <div style={{ color: "red" }} className="row">
                No datasets available!
              </div>
            )}
            <div className="row pt-3 gap-2">
              <div className="col">
                <AppSelect
                  label="State"
                  className="mb-4"
                  disabled={availableStates && availableStates.length === 0}
                  value={watch("dataset.state")}
                  options={availableStates.map((state, index) => {
                    return {
                      id: index,
                      name: state,
                    };
                  })}
                  onChange={(e) => {
                    setValue("dataset.state", e.target.value);
                    setValue("dataset.year", null);
                    setValue("dataset.version", null);
                    setValue("dataset.id", null);
                  }}
                  setNameForView={true}
                  editDetails={true}
                  required
                />
              </div>
              <div className="col">
                <AppSelect
                  label="Year"
                  className="mb-4"
                  disabled={watch("dataset.state") ? false : true}
                  value={watch("dataset.year")}
                  options={
                    availableYears.length > 0
                      ? availableYears.map((e, index) => {
                          return {
                            id: index,
                            name: e,
                          };
                        })
                      : []
                  }
                  onChange={(e) => {
                    setValue("dataset.year", e.target.value);
                    setValue("dataset.version", null);
                    setValue("dataset.id", null);
                  }}
                  setNameForView={true}
                  editDetails={true}
                  required
                />
              </div>
              <div className="col">
                <AppSelect
                  label="Version"
                  className="mb-4"
                  disabled={
                    watch("dataset.state") && watch("dataset.year")
                      ? false
                      : true
                  }
                  value={watch("dataset.version")}
                  options={
                    availableVersions.length > 0
                      ? availableVersions.map((e, index) => {
                          return {
                            id: index,
                            name: e,
                          };
                        })
                      : []
                  }
                  onChange={(ev) => {
                    setValue("dataset.version", ev.target.value);
                    setValue(
                      "dataset.id",
                      availableDatasets.find(
                        (e) =>
                          e.type === watch("dataset.type") &&
                          e.state === watch("dataset.state") &&
                          e.year === parseInt(watch("dataset.year")) &&
                          e.version === ev.target.value
                      ).id
                    );
                  }}
                  setNameForView={true}
                  editDetails={true}
                  required
                />
              </div>
            </div>
          </div>
          {watch("dataset.type") && (
            <div>
              <div className="container">
                <div className="row pageTitle section-title">
                  2. Enter Search Query
                </div>
                {tab.isAddressSearch ? (
                  <div className="row gap-2">
                    <div className="row gap-2">
                      <div className="col">
                        <AppInput
                          label="Street 1"
                          type="text"
                          value={watch("query.Street1")}
                          onChange={(e) => {
                            setValue("query.Street1", e.target.value);
                          }}
                        />
                      </div>
                      <div className="col">
                        <AppInput
                          label="Street 2"
                          type="text"
                          value={watch("query.Street2")}
                          onChange={(e) => {
                            setValue("query.Street2", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row gap-2">
                      <div className="col">
                        <AppInput
                          label="City"
                          type="text"
                          value={watch("query.City")}
                          onChange={(e) => {
                            setValue("query.City", e.target.value);
                          }}
                        />
                      </div>
                      <div className="col">
                        <AppInput
                          label="State"
                          type="text"
                          value={watch("query.State")}
                          onChange={(e) => {
                            setValue("query.State", e.target.value);
                          }}
                        />
                      </div>
                      <div className="col">
                        <AppInput
                          label="ZIP"
                          type="text"
                          value={watch("query.Zip")}
                          onChange={(e) => {
                            setValue("query.Zip", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    {watch("dataset.type") === "DL" && (
                      <div className="row gap-2">
                        <div className="row gap-2">
                          <div className="col">
                            <AppInput
                              label="Last Name"
                              type="text"
                              value={watch("query.LastName")}
                              onChange={(e) => {
                                setValue("query.LastName", e.target.value);
                              }}
                            />
                          </div>
                          <div className="col">
                            <AppInput
                              label="First Name"
                              type="text"
                              value={watch("query.FirstName")}
                              onChange={(e) => {
                                setValue("query.FirstName", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="row gap-2">
                          <div className="col">
                            <AppInput
                              label="Middle Name"
                              type="text"
                              value={watch("query.MidName")}
                              onChange={(e) => {
                                setValue("query.MidName", e.target.value);
                              }}
                            />
                          </div>
                          <div className="col">
                            <AppInput
                              label="Suffix"
                              type="text"
                              value={watch("query.Suffix")}
                              onChange={(e) => {
                                setValue("query.Suffix", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="row gap-2">
                          <div className="col">
                            <AppInput
                              label="Street"
                              type="text"
                              value={watch("query.Street")}
                              onChange={(e) => {
                                setValue("query.Street", e.target.value);
                              }}
                            />
                          </div>
                          <div className="col">
                            <AppInput
                              label="City"
                              type="text"
                              value={watch("query.City")}
                              onChange={(e) => {
                                setValue("query.City", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="row gap-2">
                          <div className="col">
                            <AppInput
                              label="Date of Birth (mm)"
                              type="text"
                              value={watch("query.BirthDate.month")}
                              onChange={(e) => {
                                formatMonth(e, "BirthDate")
                              }}
                              errorMsg={errors && errors.query && errors.query.BirthDate && errors.query.BirthDate.month&& errors.query.BirthDate.month.message}
                            />
                          </div>
                          <div className="col ">
                            <AppInput
                              label="Date of Birth (dd)"
                              type="text"
                              value={watch("query.BirthDate.day")}
                              onChange={(e) => {
                               formatDay(e, "BirthDate")
                              }}
                              errorMsg={errors && errors.query && errors.query.BirthDate && errors.query.BirthDate.day && errors.query.BirthDate.day.message}
                            />
                          </div>
                          <div className="col   col-md-4">
                            <AppInput
                              label="Date of Birth (yyyy)"
                              type="text"
                              value={watch("query.BirthDate.year")}
                              onChange={(e) => {
                                formatYear(e, "BirthDate")
                              }}
                              errorMsg={errors && errors.query && errors.query.BirthDate && errors.query.BirthDate.year && errors.query.BirthDate.year.message}
                            />
                          </div>
                        </div>
                        <div className="row gap-2">
                          <div className="col">
                            <AppInput
                              label="License Number"
                              type="text"
                              value={watch("query.LicNum")}
                              onChange={(e) => {
                                setValue("query.LicNum", e.target.value);
                              }}
                            />
                          </div>
                          <div className="col">
                            <AppInput
                              label="Height"
                              type="text"
                              value={watch("query.Height")}
                              onChange={(e) => {
                                setValue("query.Height", e.target.value);
                              }}
                            />
                          </div>
                          <div className="col">
                            <AppInput
                              label="Gender"
                              type="text"
                              value={watch("query.Sex")}
                              onChange={(e) => {
                                setValue("query.Sex", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {watch("dataset.type") === "MVR" && (
                      <div className="row gap-2">
                        <div className="row gap-2">
                          <div className="pageTitle section-title row">
                            2.1 Owner Details
                          </div>
                          <div className="row gap-2">
                            <div className="col">
                              <AppInput
                                label="Last Name"
                                type="text"
                                value={watch("query.LastName")}
                                onChange={(e) => {
                                  setValue("query.LastName", e.target.value);
                                }}
                              />
                            </div>
                            <div className="col">
                              <AppInput
                                label="First Name"
                                type="text"
                                value={watch("query.FirstName")}
                                onChange={(e) => {
                                  setValue("query.FirstName", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="row gap-2">
                            <div className="col">
                              <AppInput
                                label="Street"
                                type="text"
                                value={watch("query.Street")}
                                onChange={(e) => {
                                  setValue("query.Street", e.target.value);
                                }}
                              />
                            </div>
                            <div className="col">
                              <AppInput
                                label="City"
                                type="text"
                                value={watch("query.City")}
                                onChange={(e) => {
                                  setValue("query.City", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="row gap-2">
                            <div className="col">
                              <AppInput
                                label="State"
                                type="text"
                                value={watch("query.State")}
                                onChange={(e) => {
                                  setValue("query.State", e.target.value);
                                }}
                              />
                            </div>
                            <div className="col">
                              <AppInput
                                label="Zip"
                                type="text"
                                value={watch("query.Zip")}
                                onChange={(e) => {
                                  setValue("query.Zip", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="row gap-2">
                            <div className="col">
                              <AppInput
                                label="Date of Birth (mm)"
                                type="text"
                                value={watch("query.BirthDate.month")}
                                onChange={(e) => {
                                  formatMonth(e, "BirthDate")
                                }}
                                errorMsg={errors && errors.query && errors.query.BirthDate && errors.query.BirthDate.month && errors.query.BirthDate.month.message}
                              />
                            </div>
                            <div className="col ">
                              <AppInput
                                label="Date of Birth (dd)"
                                type="text"
                                value={watch("query.BirthDate.day")}
                                onChange={(e) => {
                                  formatDay(e, "BirthDate")
                                }}
                                errorMsg={errors && errors.query && errors.query.BirthDate && errors.query.BirthDate.day && errors.query.BirthDate.day.message}
                              />
                            </div>
                            <div className="col   col-md-4">
                              <AppInput
                                label="Date of Birth (yyyy)"
                                type="text"
                                value={watch("query.BirthDate.year")}
                                onChange={(e) => {
                                  formatYear(e, "BirthDate")
                                }}
                                errorMsg={errors && errors.query && errors.query.BirthDate && errors.query.BirthDate.year && errors.query.BirthDate.year.message}
                              />
                            </div>
                          </div>
                          <div className="row gap-2">
                            <div className="col">
                              <AppInput
                                label="License Number"
                                type="text"
                                value={watch("query.LicNum")}
                                onChange={(e) => {
                                  setValue("query.LicNum", e.target.value);
                                }}
                              />
                            </div>
                            <div className="col">
                              <AppInput
                                label="Lien Holder"
                                type="text"
                                value={watch("query.LienHolder1Name")}
                                onChange={(e) => {
                                  setValue("query.LienHolder1Name", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row gap-2">
                          <div className="pageTitle section-title row">
                            2.2 Vehicle Details
                          </div>
                          <div className="row gap-2">
                            <div className="col">
                              <AppInput
                                label="Plate"
                                type="text"
                                value={watch("query.RegNumber")}
                                onChange={(e) => {
                                  setValue("query.RegNumber", e.target.value);
                                }}
                              />
                            </div>
                            <div className="col">
                              <AppInput
                                label="Plate Color"
                                type="text"
                                value={watch("query.RegColor")}
                                onChange={(e) => {
                                  setValue("query.RegColor", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="row gap-2">
                            <div className="col">
                              <AppInput
                                label="Color"
                                type="text"
                                value={watch("query.Color")}
                                onChange={(e) => {
                                  setValue("query.Color", e.target.value);
                                }}
                              />
                            </div>
                            <div className="col">
                              <AppInput
                                label="Make"
                                type="text"
                                value={watch("query.Manufacturer")}
                                onChange={(e) => {
                                  setValue("query.Manufacturer", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="row gap-2">
                            <div className="col">
                              <AppInput
                                label="Model"
                                type="text"
                                value={watch("query.ModelName")}
                                onChange={(e) => {
                                  setValue("query.ModelName", e.target.value);
                                }}
                              />
                            </div>
                            <div className="col">
                              <AppInput
                                label="Year"
                                type="text"
                                value={watch("query.ModelYear")}
                                onChange={(e) => {
                                  setValue("query.ModelYear", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="row gap-2">
                            <div className="col">
                              <AppInput
                                label="VIN"
                                type="text"
                                value={watch("query.VIN")}
                                onChange={(e) => {
                                  setValue("query.VIN", e.target.value);
                                }}
                              />
                            </div>
                            <div className="col">
                              <AppInput
                                label="Doors"
                                type="text"
                                value={watch("query.NumDoors")}
                                onChange={(e) => {
                                  setValue("query.NumDoors", e.target.value);
                                }}
                              />
                            </div>
                            <div className="col">
                              <AppInput
                                label="Cylinders"
                                type="text"
                                value={watch("query.NumCylinders")}
                                onChange={(e) => {
                                  setValue(
                                    "query.NumCylinders",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row gap-2">
                          <div className="pageTitle section-title row">
                            2.3 Other Details
                          </div>
                          <div className="row gap-2">
                            <div className="col">
                              <AppInput
                                label="Co Name"
                                type="text"
                                value={watch("query.CorpName")}
                                onChange={(e) => {
                                  setValue("query.CorpName", e.target.value);
                                }}
                              />
                            </div>
                            <div className="col">
                              <AppInput
                                label="Title Num"
                                type="text"
                                value={watch("query.TitleNum")}
                                onChange={(e) => {
                                  setValue("query.TitleNum", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="row gap-2">
                            <div className="col">
                              <AppInput
                                label="Insurance Code"
                                type="text"
                                value={watch("query.InsuranceCode")}
                                onChange={(e) => {
                                  setValue(
                                    "query.InsuranceCode",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <div className="col">
                              <AppInput
                                label="Reg Type"
                                type="text"
                                value={watch("query.VehTypeName")}
                                onChange={(e) => {
                                  setValue("query.VehTypeName", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="row gap-2">
                            <div className="col">
                              <AppInput
                                label="Reg Exp Date (mm)"
                                type="text"
                                value={watch("query.RegExpDate.month")}
                                onChange={(e) => {
                                  formatMonth(e, "RegExpDate")
                                }}
                                errorMsg={errors && errors.query && errors.query.RegExpDate && errors.query.RegExpDate.month && errors.query.RegExpDate.month.message}
                              />
                            </div>
                            <div className="col ">
                              <AppInput
                                label="Reg Exp Date (dd)"
                                type="text"
                                value={watch("query.RegExpDate.day")}
                                onChange={(e) => {
                                  formatDay(e, "RegExpDate")
                                }}
                                errorMsg={errors && errors.query && errors.query.RegExpDate && errors.query.RegExpDate.day && errors.query.RegExpDate.day.message}
                              />
                            </div>
                            <div className="col   col-md-4">
                              <AppInput
                                label="Reg Exp Date (yyyy)"
                                type="text"
                                value={watch("query.RegExpDate.year")}
                                onChange={(e) => {
                                  formatYear(e, "RegExpDate")
                                }}
                                errorMsg={errors && errors.query && errors.query.RegExpDate && errors.query.RegExpDate.year && errors.query.RegExpDate.year.message}
                              />
                            </div>
                          </div>
                          <div className="row gap-2">
                            <div className="col">
                              <AppInput
                                label="Reg Effective Date (mm)"
                                type="text"
                                value={watch("query.RegEffectiveDate.month")}
                                onChange={(e) => {
                                  formatMonth(e, "RegEffectiveDate")
                                }}
                                errorMsg={errors && errors.query && errors.query.RegEffectiveDate && errors.query.RegEffectiveDate.month && errors.query.RegEffectiveDate.month.message}
                              />
                            </div>
                            <div className="col ">
                              <AppInput
                                label="Reg Effective Date (dd)"
                                type="text"
                                value={watch("query.RegEffectiveDate.day")}
                                onChange={(e) => {
                                  formatDay(e, "RegEffectiveDate")
                                }}
                                errorMsg={errors && errors.query && errors.query.RegEffectiveDate && errors.query.RegEffectiveDate.day && errors.query.RegEffectiveDate.day.message}
                              />
                            </div>
                            <div className="col   col-md-4">
                              <AppInput
                                label="Reg Effective Date (yyyy)"
                                type="text"
                                value={watch("query.RegEffectiveDate.year")}
                                onChange={(e) => {
                                  formatYear(e, "RegEffectiveDate")
                                }}
                                errorMsg={errors && errors.query && errors.query.RegEffectiveDate && errors.query.RegEffectiveDate.year && errors.query.RegEffectiveDate.year.message}
                              />
                            </div>
                          </div>
                          <div className="row gap-2">
                            <div className="col">
                              <AppInput
                                label="Reg Issue Date (mm)"
                                type="text"
                                value={watch("query.RegIssueDate.month")}
                                onChange={(e) => {
                                  formatMonth(e, "RegIssueDate")
                                }}
                                errorMsg={errors && errors.query && errors.query.RegIssueDate && errors.query.RegIssueDate.month && errors.query.RegIssueDate.month.message}
                              />
                            </div>
                            <div className="col ">
                              <AppInput
                                label="Reg Issue Date (dd)"
                                type="text"
                                value={watch("query.RegIssueDate.day")}
                                onChange={(e) => {
                                  formatDay(e, "RegIssueDate")
                                }}
                                errorMsg={errors && errors.query && errors.query.RegIssueDate && errors.query.RegIssueDate.day && errors.query.RegIssueDate.day.message}
                              />
                            </div>
                            <div className="col   col-md-4">
                              <AppInput
                                label="Reg Issue Date (yyyy)"
                                type="text"
                                value={watch("query.RegIssueDate.year")}
                                onChange={(e) => {
                                  formatYear(e, "RegIssueDate")
                                }}
                                errorMsg={errors && errors.query && errors.query.RegIssueDate && errors.query.RegIssueDate.year && errors.query.RegIssueDate.year.message}
                              />
                            </div>
                          </div>
                          <div className="row gap-2">
                            <div className="col">
                              <AppInput
                                label="Passengers"
                                type="text"
                                value={watch("query.NumPassengers")}
                                onChange={(e) => {
                                  setValue("query.NumPassengers", e.target.value);
                                }}
                              />
                            </div>
                            <div className="col">
                              <AppInput
                                label="Weight"
                                type="text"
                                value={watch("query.Weight")}
                                onChange={(e) => {
                                  setValue("query.Weight", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="container mt-4">
                <div className="row row-cols-auto justify-content-center">
                  <div className="col">
                    <div className="DPPAsection">
                      <div className="DPPAsectionCheckBox">
                        <Input
                          type="checkbox"
                          value={watch("agree")}
                          onChange={(e) => setValue("agree", e.target.checked)}
                        />
                      </div>
                      <div className="DPPAsectionText">
                        <div className="DPPAsectionTextLabel">
                          <Label check>I agree to DDPA use</Label>
                        </div>
                        <div
                          className="DPPAsectionTextSubLabel"
                          onClick={() => toggleDppa()}
                        >
                          <p>Read Agreement</p>
                        </div>
                      </div>
                    </div>
                    <Button
                      disabled={
                        !watch("agree") ||
                        !watch("dataset.state") ||
                        !watch("dataset.year") ||
                        !watch("dataset.version") || !isValid
                      }
                      onClick={handleSearch}
                      style={{ width: 140, marginTop: 10 }}
                    >
                      Search
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default SubscriptionSearchQueryForm;
