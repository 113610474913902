import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import AppInput from "../../Components/AppInput/AppInput";
import AppSelect from "../../Components/AppSelect/AppSelect";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { useHistory } from "react-router";
import ConfirmModal from "../../Components/ConfirmModal/ConfirmModal";
import AppAlert from "../../Components/AppAlert/AppAlert";
import alertInfoIcon from "../../assets/icons/small-timer.svg";
import useRestrictedStatesList from "../../shared/hooks/useRestrictedStatesList.jsx";
import DppaModal from "../../Components/DppaModal/DppaModal";

const confirmSearchModalActionBtns = [
  { name: "Yes, Search", submit: true },
  { name: "Cancel", className: "cancelBtn", submit: false },
];

export default function Dashboard(props) {
  useDocumentTitle("Search");

  const [confirmSearchModal, setConfirmSearchModal] = useState(false);
  const [dppaModal, setDppaModal] = useState(false);
  const [licencePlateNumber, setLicensePlateNumber] = useState("");
  const [stateOfIssuance, setStateOfIssuance] = useState("");
  const [stateOfIssuanceInitials, setStateOfIssuanceInitials] = useState("");
  const [DPPAAgree, setDPPAAgree] = useState(false);
  const [open, setOpen] = React.useState(
    props.showAlert ? props.showAlert : false
  );
  const [error, setError] = useState(false);

  const { statesList, setStateList, loader } = useRestrictedStatesList();

  const history = useHistory();

  useEffect(() => {
    getStateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (dppaModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }

    return () => (document.body.style.overflow = "unset");
  }, [dppaModal]);

  const getStateList = async () => {
    if (statesList && !statesList.length) {
      await setStateList();
    }
  };

  const searchRecord = async () => {
    history.push("/account/search/result/true");
  };

  function searchClicked(e) {
    if (licencePlateNumber === "" || licencePlateNumber === null) {
      setError(true);
      return true;
    } else if (stateOfIssuance === "" || stateOfIssuance === null) {
      setError(true);
      return true;
    } else {

      let stateOfIssuanceIni = statesList?.filter((curInitials) => curInitials?.id === stateOfIssuance);
      stateOfIssuanceIni = stateOfIssuanceIni?.find((e) => e)?.initials;
      setStateOfIssuanceInitials(stateOfIssuanceIni);
      sessionStorage.setItem("licencePlateNumber", licencePlateNumber);
      sessionStorage.setItem("licensePlateState", stateOfIssuanceIni);
      setConfirmSearchModal(true);
    }
  }

  return (
    <>
      <AppAlert
        open={open}
        onCloseClick={() => setOpen(false)}
        closeBtn={true}
        icon={alertInfoIcon}
        message="Please capture your search results as they are stored and available only for 72 hours."
      />
      {/* <AppLoader isLoading={loader} /> */}
      <div className={`container-fluid ${open ? "mt-4" : ""}`}>
        <div className="searchTitle">My Search</div>
        <div className="searchSection">
          <Row className="">
            <Col md={{ size: 3 }} className="searchSectionCol width30">
              <AppInput
                label="License Plate Number"
                type="text"
                className={"mt-2"}
                value={licencePlateNumber}
                validations={{
                  required: true,
                }}
                errorMsg={
                  error && !licencePlateNumber
                    ? "License Plate Number is required."
                    : ""
                }
                onChange={(e) => setLicensePlateNumber(e.target.value)}
              />
            </Col>
            <Col
              md={{ size: 3 }}
              className="searchSectionCol width30 searchSectionSelect"
            >
              <AppSelect
                label="State of Issuance"
                value={stateOfIssuance}
                className={"mt-2"}
                options={statesList}
                isAbbr={true}
                onChange={(e) => setStateOfIssuance(e.target.value)}
                required
                errorMsg={
                  error && !stateOfIssuance
                    ? "State of Issuance is required."
                    : ""
                }
              />
            </Col>
            <Col
              md={{ size: 3 }}
              className="searchSectionCol text-center width25 checkBoxDiv"
            >
              <FormGroup check className="DPPAcheckbox">
                <div className="DPPAsection">
                  <div className="DPPAsectionCheckBox">
                    <Input
                      type="checkbox"
                      value={DPPAAgree}
                      onChange={(e) => setDPPAAgree(e.target.checked)}
                    />
                  </div>
                  <div className="DPPAsectionText">
                    <div className="DPPAsectionTextLabel">
                      <Label check>I agree to DDPA use</Label>
                    </div>
                    <div
                      className="DPPAsectionTextSubLabel"
                      onClick={() => setDppaModal(true)}
                    >
                      <p>Read Agreement</p>
                    </div>
                  </div>
                </div>
              </FormGroup>
            </Col>
            <Col
              md={{ size: 3 }}
              className="searchBtnContainer searchSectionCol width15"
            >
              <div className="text-end">
                <Button
                  style={{ marginTop: 5 }}
                  className="searchBtn"
                  type="button"
                  disabled={!DPPAAgree}
                  onClick={searchClicked}
                >
                  Search
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        {confirmSearchModal && (
          <ConfirmModal
            title="Please confirm the information you are searching is correct"
            actionBtns={confirmSearchModalActionBtns}
            onClose={(confirmSearchFlag) => {
              if (typeof confirmSearchFlag == "boolean" && confirmSearchFlag) {
                searchRecord();
              }
              setConfirmSearchModal(false);
            }}
          >
            <div className="my-5 text-center">
              <Row>
                <Col md={{ size: 6 }}>
                  <div className="labelName">License Plate Number</div>
                  <div className="labelValue">{licencePlateNumber}</div>
                </Col>
                <Col md={{ size: 6 }}>
                  <div className="labelName">State of Issuance</div>
                  <div className="labelValue">{stateOfIssuanceInitials}</div>
                </Col>
              </Row>
            </div>
          </ConfirmModal>
        )}
        {dppaModal && <DppaModal onClose={() => setDppaModal(false)} />}
      </div>
    </>
  );
}
