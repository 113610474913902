import { TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import useTable from "../../Components/useTable";
import "./UserList.css";
import InfiniteScroll from "react-infinite-scroll-component";
import TableLoader from "../../Components/TableLoader/TableLoader";
import { getListOfUsers } from "../../Utils/services/accounts.service";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import { useHistory } from "react-router";
import AppLoader from "../../Components/AppLoader/AppLoader";
import searchIcon from "../../assets/icons/small_search.png";
import IconAppInput from "../../Components/IconAppInput/IconAppInput";
import ConfirmModal from "../../Components/ConfirmModal/ConfirmModal";
import { ToastrService } from "../../Utils/services/tostr.service";
import {
  deleteUser,
  resendInviteToUser,
} from "../../Utils/services/user.service";
import { convertTZ, roles } from "../../shared/constants/constants";
import { Auth } from "../../Utils/helpers";
import dayjs from "dayjs";

const headCells = [
  { id: "firstName", label: "FIRST NAME", width: "20%" },
  { id: "lastName", label: "LAST NAME", width: "15%" },
  {
    id: "email",
    label: "EMAIL ID",
    disableSorting: true,
  },
  { id: "DATE ADDED", label: "DATE ADDED", width: "10%", disableSorting: true },
  { id: "userRole", label: "User Role", width: "15%", disableSorting: true },
  { id: "status", label: "STATUS", width: "10%", disableSorting: true },
  { id: "action", label: "Action", width: "5%", disableSorting: true },
];

const resendEmailModalActionBtns = [
  { name: "Resend Email", submit: true },
  { name: "Cancel", className: "cancelBtn", submit: false },
];

const deleteModalActionBtns = [
  { name: "Yes, Delete", submit: true },
  { name: "Cancel", className: "cancelBtn", submit: false },
];

export default function AccountsList() {
  useDocumentTitle("Users");

  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [error, setError] = useState("");
  const [loader, setloader] = useState(false);
  const [order, setOrder] = useState("DESC");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const { TblContainer, TblHead } = useTable(data, headCells);

  const [resendEmailModal, setResendEmailModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);

  const history = useHistory();

  const fetchListOfUsers = async (resetData = false) => {
    setloader(true);
    try {
      let response = await getListOfUsers({
        sortDirection: order,
        sortBy: orderBy,
        pageNumber: page,
        size: 15,
        searchBy: searchQuery,
      });
      if (response.code === 200) {
        const temp = Array.from(resetData || !page ? [] : data);
        if (response.body) {
          temp.push(...response.body.content);
          temp.forEach((item) => {
            const date = convertTZ(item.onBoardedDate);
            item.onBoardedDate = dayjs(date).format("MM-DD-YYYY");
          });
          setData(temp);
          setTotalRecords(response.body.totalElements);
        }
        setloader(false);
        setIsLoading(false);
      } else {
        setloader(false);
        setIsLoading(false);
        setError(response.message);
      }
    } catch (error) {
      setloader(false);
      setIsLoading(false);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchListOfUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, page, searchQuery]);

  const handleSortRequest = (orderBy, order) => {
    document.getElementsByClassName("userListTable")[0].scrollTo(0, 0);
    setOrder(order);
    setPage(0);
    setOrderBy(orderBy);
    setTimeout(() => {
      setData([]);
    }, 10);
  };

  const addNewData = () => {
    setPage(page + 1);
  };

  const resendEmailToAccount = async () => {
    setIsLoading(true);
    try {
      let response = await resendInviteToUser(selectedUser);

      if (response.code === 200) {
        ToastrService.success(response.message);
      } else {
        ToastrService.error(response.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      ToastrService.error(error.message);
    }
  };

  const deleteAccountClicked = async () => {
    setIsLoading(true);
    try {
      let response = await deleteUser(selectedUser.id);

      if (response.code === 200) {
        ToastrService.success(response.message);
        fetchListOfUsers(true);
      } else {
        ToastrService.error(response.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      ToastrService.error(error.message);
    }
  };

  const checkIfUserHaveEditDeletePermission = (item) => {
    if (
      item.roles.includes(roles.company_admin) &&
      Auth.getUserRole().includes(roles.admin)
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <AppLoader isLoading={isLoading} />
      <div className="container-fluid">
        <div className="userListSection">
          <Row className="mb-2">
            <Col lg={{ size: 6 }}>
              <div>
                <div className="pageTitle">List of Registered Users</div>
              </div>
            </Col>
            <Col lg={{ size: 6 }}>
              <div className="listSearchBar">
                <div className="searchInput">
                  <IconAppInput
                    label="Search..."
                    icon={searchIcon}
                    placeholder="Search by Name/Email ID"
                    onEnter={(value) => {
                      if (searchQuery !== value) {
                        setData([]);
                        setTotalRecords(0);
                        setPage(0);
                        setSearchQuery(value);
                      }
                    }}
                  />
                </div>
                <div className="accountListIcons text-end">
                  <div
                    className="smallAddIcon"
                    onClick={() => {
                      history.push("/account/addUser");
                    }}
                  ></div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              {error !== "" && (
                <span className="text-danger errorMsg">{error}</span>
              )}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <InfiniteScroll
                className="userListTable"
                dataLength={data.length}
                next={() => addNewData()}
                height="100%"
                hasMore={data.length < totalRecords}
                endMessage={
                  !loader &&
                  !data.length && (
                    <p className="text-secondary mt-3 text-center">
                      No Result Found
                    </p>
                  )
                }
              >
                <TblContainer>
                  <TblHead
                    handleSort={(orderBy, order) =>
                      handleSortRequest(orderBy, order)
                    }
                  />
                  <TableBody>
                    {data.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell
                          title={item.firstName}
                          className="companyNameColumn"
                          onClick={() => {
                            history.push(
                              `/account/addUser?view=true&email=${item.email}`
                            );
                          }}
                        >
                          {item.firstName}
                          {item.email === Auth.getUserEmail() ? ` (You)` : ""}
                        </TableCell>
                        <TableCell>{item.lastName}</TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell className="dateAddedColumn">
                          {item.onBoardedDate}
                        </TableCell>
                        <TableCell>
                          {item?.roles?.map((ele, index) => {
                            if (ele === roles?.admin) {
                              return ele?.replace("_", " ");
                            } else if (ele === roles?.non_admin) {
                              return ele?.replace("_", " ");
                            } else if (ele === roles?.company_admin) {
                              return ele?.replace("_", " ");
                            }
                          })}
                        </TableCell>
                        <TableCell className="statusColumn">
                          {item.isActive ? "Active" : "Inactive"}
                        </TableCell>
                        <TableCell>
                          <div className="accountListIconSection text-start">
                            <div
                              title="Edit Account"
                              className={`smallEditIcon ${
                                !checkIfUserHaveEditDeletePermission(item)
                                  ? "disableIcon"
                                  : ""
                              }`}
                              onClick={() => {
                                if (checkIfUserHaveEditDeletePermission(item)) {
                                  history.push(
                                    `/account/addUser?edit=true&email=${item.email}`
                                  );
                                }
                              }}
                            ></div>
                            <div
                              title="Resend Invite"
                              className={`smallResendInviteIcon ${
                                !checkIfUserHaveEditDeletePermission(item)
                                  ? "disableIcon"
                                  : ""
                              }`}
                              onClick={() => {
                                if (checkIfUserHaveEditDeletePermission(item)) {
                                  setSelectedUser(item);
                                  setResendEmailModal(true);
                                }
                              }}
                            ></div>
                            <div
                              title="Delete Account"
                              className={`smallDeleteIcon ${
                                !checkIfUserHaveEditDeletePermission(item)
                                  ? "disableIcon"
                                  : ""
                              }`}
                              onClick={() => {
                                if (checkIfUserHaveEditDeletePermission(item)) {
                                  setSelectedUser(item);
                                  setDeleteModal(true);
                                }
                              }}
                            ></div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                    {loader && <TableLoader columnsNumber={7} />}
                  </TableBody>
                </TblContainer>
              </InfiniteScroll>
            </Col>
          </Row>
        </div>
      </div>
      {resendEmailModal && (
        <ConfirmModal
          title="Are you sure you want to resend email to the account?"
          actionBtns={resendEmailModalActionBtns}
          onClose={(resendEmailFlag) => {
            if (typeof resendEmailFlag == "boolean" && resendEmailFlag) {
              resendEmailToAccount();
            }
            setResendEmailModal(false);
          }}
        >
          <div className="my-5 text-center">
            <Row>
              <Col md={{ size: 6 }}>
                <div className="labelName">Email ID</div>
                <div className="labelValue emailValue">
                  {selectedUser?.email}
                </div>
              </Col>
              <Col md={{ size: 6 }}>
                <div className="labelName">WWI Account Number</div>
                <div className="labelValue">{selectedUser?.accountNumber}</div>
              </Col>
            </Row>
          </div>
        </ConfirmModal>
      )}
      {deleteModal && (
        <ConfirmModal
          title="Are you sure you want to delete the user?"
          actionBtns={deleteModalActionBtns}
          onClose={(deleteFlag) => {
            if (typeof deleteFlag == "boolean" && deleteFlag) {
              deleteAccountClicked();
            }
            setDeleteModal(false);
          }}
        >
          <div className="my-5 text-center">
            <Row>
              <Col md={{ size: 6 }}>
                <div className="labelName">Full Name</div>
                <div className="labelValue">{`${selectedUser?.firstName} ${selectedUser?.lastName}`}</div>
              </Col>
              <Col md={{ size: 6 }}>
                <div className="labelName">Email ID</div>
                <div className="labelValue userEmail">
                  {selectedUser?.email}
                </div>
              </Col>
            </Row>
          </div>
        </ConfirmModal>
      )}
    </>
  );
}
