import { useEffect } from "react";
import { useAuth } from "./AuthContext";
import { useHistory } from "react-router-dom";

const useAutoLogout = () => {
  const { logout } = useAuth();
  const history = useHistory();
  const logoutTimer = 15 * 60 * 1000; // 15 minutes
  // const logoutTimer = 15 * 1000; // 15 minutes

  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        logout();
        history.push("/login");
      }, logoutTimer);
    };

    const events = ["click", "mousemove", "keypress"];

    events.forEach((event) => window.addEventListener(event, resetTimeout));

    resetTimeout(); // Initialize the timeout on mount

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      events.forEach((event) =>
        window.removeEventListener(event, resetTimeout)
      );
    };
  }, [logout, logoutTimer, history]);
};

export default useAutoLogout;
